import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import resume from "./assets/My-Resume.pdf";

const SocialIcon = (props) => {
  let { classname } = props;
  return (
    <>
      <div className={`${classname} btn-wrapper profile pt-3 pb-2`}>
        {/* Instagram */}
        <Button
          className="btn-icon btn-round"
          color="dribbble"
          href="https://www.instagram.com/the_bluebae_/"
          id="instagram"
          target="_blank"
        >
          <i className="fab fa-instagram" />
        </Button>
        <UncontrolledTooltip delay={0} target="instagram">
          Follow us
        </UncontrolledTooltip>
        {/* Facebook */}
        <Button
          className="btn-icon btn-round"
          color="twitter"
          href="https://www.facebook.com/muthu.don.104/?viewas=100000686899395&show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_transition=0&show_community_review_changes=0"
          id="facebook"
          target="_blank"
        >
          <i className="fab fa-facebook-square" />
        </Button>
        <UncontrolledTooltip delay={0} target="facebook">
          Like us
        </UncontrolledTooltip>
        {/* Youtube */}
        <Button
          className="btn-icon btn-round"
          color="dribbble"
          href="https://www.youtube.com/channel/UC5IOWHr_MwBorfQpJre14vA"
          id="youtube"
          target="_blank"
        >
          <i className="fab fa-youtube" />
        </Button>
        <UncontrolledTooltip delay={0} target="youtube">
          Subscribe Us
        </UncontrolledTooltip>
        {/* twitter */}
        <Button
          className="btn-icon btn-round"
          color="twitter"
          href="https://twitter.com/krrish_m_k"
          id="twitter"
          target="_blank"
        >
          <i className="fab fa-twitter" />
        </Button>
        <UncontrolledTooltip delay={0} target="twitter">
          Follow us
        </UncontrolledTooltip>

        {/* Github */}
        <Button
          className="btn-icon btn-round"
          color="github"
          href="https://github.com/KRRISHMK"
          id="github"
          target="_blank"
        >
          <i className="fab fa-github" />
        </Button>
        <UncontrolledTooltip delay={0} target="github">
          Follow us
        </UncontrolledTooltip>

        {/* Linked In */}
        <Button
          className="btn-icon btn-round"
          color="twitter"
          href="https://www.linkedin.com/in/muthu-krishnan-2b773a159/"
          id="linkedin"
          target="_blank"
        >
          <i className="fab fa-linkedin" />
        </Button>
        <UncontrolledTooltip delay={0} target="linkedin">
          Follow us
        </UncontrolledTooltip>

        {/* stackOverflow */}
        <Button
          className="btn-icon btn-round"
          color="stackOverflow"
          href="https://stackoverflow.com/users/13472030/muthu-krishnan"
          id="stackOverflow"
          target="_blank"
        >
          <i className="fab fa-stack-overflow" />
        </Button>
        <UncontrolledTooltip delay={0} target="stackOverflow">
          Follow us
        </UncontrolledTooltip>

        {/* CodePen */}
        <Button
          className="btn-icon btn-round"
          color="github"
          href="https://codepen.io/krrishmk"
          id="codepen"
          target="_blank"
        >
          <i className="fab fa-codepen" />
        </Button>
        <UncontrolledTooltip delay={0} target="codepen">
          Follow us
        </UncontrolledTooltip>
      </div>
      <a
        href={resume}
        download="KrrishMK-Resume"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          className={`${classname} btn-round`}
          color="primary"
          type="button"
        >
          <i className="tim-icons icon-cloud-download-93 pr-1" />
          Resume
        </Button>
      </a>
    </>
  );
};

export default SocialIcon;
