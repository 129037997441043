import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

const ContactUs = () => {
  const handleSend = (data) => {
    console.log(data);
  };
  const iframe =
    '<iframe src="https://maps.google.com/maps?width=100%25&amp;height=290&amp;hl=en&amp;q=Gudalur,%20The%20Nilgiris+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>';
  return (
    <div className="wrapper">
      <img
        alt="..."
        className="dots"
        src={require("assets/img/dots.png").default}
      />
      <img
        alt="..."
        className="path"
        src={require("assets/img/path4.png").default}
      />
      <section className="section">
        <Container>
          <Row>
            <Col md="6">
              <Card className="card-plain">
                <CardHeader>
                  <h1 className="profile-title text-left">Contact</h1>
                  <h5 className="text-on-back">05</h5>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={(values) => handleSend(values)}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Your Name</label>
                          <Input
                            name="name"
                            className="form-control-success"
                            required
                            placeholder="Enter Your Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Email address</label>
                          <Input
                            name="email"
                            required
                            placeholder="Enter Your Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Phone</label>
                          <Input
                            name="number"
                            type="number"
                            placeholder="Enter Your Number"
                            defaultValue="+91"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                        <FormGroup>
                          <label>Department</label>
                          <Input defaultValue="ECE" type="text" />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Message</label>
                          <Input
                            name="message"
                            placeholder="Hello there!"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between">
                      <Button
                        className="btn-round btn-green float-right"
                        color="green"
                        type="button"
                        onClick={() =>
                          (window.location.href =
                            "https://api.whatsapp.com/send?phone=+919994171573&text=Hello%21%21%21%20%20%20Krrish%20MK.")
                        }
                      >
                        Whatsapp
                      </Button>
                      <Button
                        className="btn-round float-right"
                        color="primary"
                        type="submit"
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col className="ml-auto" md="4">
              <div className="info info-horizontal">
                <div className="icon icon-primary">
                  <i className="tim-icons icon-square-pin" />
                </div>
                <div className="description">
                  <h4 className="info-title">Find us at the office</h4>
                  <p>
                    56 Big Thaikkal Street <br />
                    Mayiladuthurai, <br />
                    609001
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <a href="tel: +919994171573">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                </a>
                <div className="description">
                  <h4 className="info-title">
                    Give us a ring
                    <a href="tel: +919994171573">
                      <i className=" pl-1 fa fa-phone" />
                    </a>
                  </h4>
                  <p>
                    Muthu Krishnan - BE <br />
                    muthukrrish333@gmail.com <br />
                    +91 9994171573 <br />
                    Mon - Fri, 8:00-22:00
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default ContactUs;
