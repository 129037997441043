import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledCarousel,
} from "reactstrap";

const Project = () => {
  const myGuiderImage = [
    {
      src: require("./assets/myguider/1.png").default,
      altText: "Slide 1",
      caption: "Login Page",
    },
    {
      src: require("./assets/myguider/2.png").default,
      altText: "Slide 2",
      caption: "Public Page",
    },
    {
      src: require("./assets/myguider/3.png").default,
      altText: "Slide 3",
      caption: "Admin Dashboard",
    },
    {
      src: require("./assets/myguider/4.png").default,
      altText: "Slide 3",
      caption: "Add Book Modal",
    },
    {
      src: require("./assets/myguider/5.png").default,
      altText: "Slide 3",
      caption: "Edit Book Modal",
    },
    {
      src: require("./assets/myguider/6.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/7.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/8.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/9.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/10.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/11.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/12.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
    {
      src: require("./assets/myguider/13.png").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
  ];
  const libraryImage = [
    {
      src: require("./assets/library/lib1.jpg").default,
      altText: "Slide 1",
      caption: "Login Page",
    },
    {
      src: require("./assets/library/lib2.jpg").default,
      altText: "Slide 2",
      caption: "Public Page",
    },
    {
      src: require("./assets/library/lib3.jpg").default,
      altText: "Slide 3",
      caption: "Admin Dashboard",
    },
    {
      src: require("./assets/library/lib4.jpg").default,
      altText: "Slide 3",
      caption: "Add Book Modal",
    },
    {
      src: require("./assets/library/lib5.jpg").default,
      altText: "Slide 3",
      caption: "Edit Book Modal",
    },
    {
      src: require("./assets/library/lib6.jpg").default,
      altText: "Slide 3",
      caption: "Student Requested Book List page",
    },
  ];
  const fogImages = [
    {
      src: require("./assets/fog/lib1.png").default,
      altText: "Slide 1",
      caption: "Home Page",
    },
    {
      src: require("./assets/fog/lib2.png").default,
      altText: "Slide 2",
      caption: "Partner Carousel",
    },
    {
      src: require("./assets/fog/lib3.png").default,
      altText: "Slide 3",
      caption: "Gallery",
    },
    {
      src: require("./assets/fog/lib4.png").default,
      altText: "Slide 3",
      caption: "Gallery View",
    },
    {
      src: require("./assets/fog/lib5.png").default,
      altText: "Slide 3",
      caption: "Register Page",
    },
    {
      src: require("./assets/fog/lib6.png").default,
      altText: "Slide 3",
      caption: "Event Page",
    },
  ];
  const moiraImages = [
    {
      src: require("./assets/moira/moira1.png").default,
      altText: "Slide 1",
      caption: "Home Page",
    },
    {
      src: require("./assets/moira/moira2.png").default,
      altText: "Slide 2",
      caption: "Header",
    },
    {
      src: require("./assets/moira/moira3.png").default,
      altText: "Slide 3",
      caption: "Carousel",
    },
    {
      src: require("./assets/moira/moira4.png").default,
      altText: "Slide 3",
      caption: "Footer Content",
    },
    {
      src: require("./assets/moira/moira5.png").default,
      altText: "Slide 3",
      caption: "Clients",
    },
    {
      src: require("./assets/moira/moira6.png").default,
      altText: "Slide 3",
      caption: "Event Page",
    },
  ];
  const projectData = [
    {
      name: "MyGuider for Education",
      des: "Admission Management Platform for  Schools & Colleges to Discover, evaluate, and apply to your dream institution directly, all in one place.",
      image: myGuiderImage,
      // link: "http://libraryfrontend.herokuapp.com/",
    },
    {
      name: "Library Management",
      des: "The Project helps to reduce operational costs. Managing a library manually is labor intensive and an immense amount of paperwork is involved. An automated system reduces the need for manpower and stationery. This leads to lower operational costs.",
      image: libraryImage,
      link: "http://libraryfrontend.herokuapp.com/",
    },
    {
      name: "FOG Fashion",
      des: "This Project is just a Fashion Studio Website for marketing and advertising their Event",
      image: fogImages,
      link: "https://fogfashionstudio.in/",
    },
    {
      name: "Moira Buildings",
      des: "This is a Company Website for showing up their Company Product and Details.",
      image: moiraImages,
      link: "http://moirabuildingsystems.in/",
    },
    // {
    //   name: "Library Management",
    //   des: "The Project helps to reduce operational costs. Managing a library manually is labor intensive and an immense amount of paperwork is involved. An automated system reduces the need for manpower and stationery. This leads to lower operational costs.",
    //   image: libraryImage,
    //   link: "/http://libraryfrontend.herokuapp.com/",
    // },
  ];

  return (
    <div className="section">
      <Container>
        <Card className="card-plain">
          <CardHeader>
            <h1 className="profile-title text-left">Self Projects</h1>
            <h5 className="text-on-back">04</h5>
          </CardHeader>
        </Card>

        {projectData &&
          projectData.map((data) => (
            <>
              <div className="title">
                <h3>{}</h3>
              </div>
              <Row className="justify-content-between align-items-center">
                <Col className="mb-5 mb-lg-0" lg="5">
                  <h1 className="text-white font-weight-light">{data.name}</h1>
                  <p className="text-white mt-4">{data.des}</p>
                  {/* <Button
                    className="mt-4"
                    color="primary"
                    href={data.link}
                    target="_blank"
                  >
                    Demo
                  </Button> */}
                </Col>
                <Col lg="6">
                  <UncontrolledCarousel
                    items={data.image}
                    indicators={false}
                    autoPlay={true}
                  />
                </Col>
              </Row>
            </>
          ))}
      </Container>
    </div>
  );
};

export default Project;
