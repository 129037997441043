import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

const Experience = () => {
  const [iconTabs, setIconsTabs] = React.useState(1);
  const [iconTabs1, setIconsTabs1] = React.useState(1);
  const [textTabs, setTextTabs] = React.useState(4);
  return (
    <div>
      <Container>
        <Card className="card-plain">
          <CardHeader>
            <h1 className="profile-title text-left">Experience</h1>
            <h5 className="text-on-back">02</h5>
          </CardHeader>
        </Card>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 1,
                      })}
                      onClick={(e) => setIconsTabs(1)}
                    >
                      <i className="tim-icons icon-spaceship" />
                      Company
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 2,
                      })}
                      onClick={(e) => setIconsTabs(2)}
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                      Project
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 3,
                      })}
                      onClick={(e) => setIconsTabs(3)}
                    >
                      <i className="tim-icons icon-bag-16" />
                      Responsibility
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="tab-space"
                  activeTab={"link1" + iconTabs}
                >
                  <TabPane tabId="link11">
                    <span className="font-weight-bold">
                      Company Name&nbsp;&nbsp;:{" "}
                    </span>{" "}
                    <span>&nbsp;CENSA NEXT PVT. LTD</span>
                    <br />
                    <span className="font-weight-bold">
                      Designation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </span>{" "}
                    <span>&nbsp;TECH LEAD</span>
                    <br />
                    <span className="font-weight-bold">
                      Experience&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                    </span>
                    <span>&nbsp;2022-CURRENT</span>
                  </TabPane>
                  <TabPane tabId="link12">
                    <p>
                      <span className="font-weight-bold">Project Name:</span>{" "}
                      FARM TECH E2E Solutions with integrated SAP <br />
                      <span className="font-weight-bold">Role:</span>{" "}
                      <span className="pl-5 ml-3"> Team Lead,</span> <br />
                      <span className="font-weight-bold">Environment:</span>
                      <span className="pl-2">
                        ReactJS, NodeJS, MongoDB, ExpressJS , MySQL, Redux and
                        React Query
                      </span>
                    </p>
                  </TabPane>
                  <TabPane tabId="link13">
                    <ul>
                      <li>Project Leadership</li>
                      <li>
                        Structuring Database structure and assigning tasks to
                        team
                      </li>
                      <li>
                        Building end to end farm tech solution with integrated
                        SAP.
                      </li>
                      <li>Revamping existing structure to Microservices.</li>
                    </ul>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs1 === 1,
                      })}
                      onClick={(e) => setIconsTabs1(1)}
                    >
                      <i className="tim-icons icon-spaceship" />
                      Company
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs1 === 2,
                      })}
                      onClick={(e) => setIconsTabs1(2)}
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                      Project
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs1 === 3,
                      })}
                      onClick={(e) => setIconsTabs1(3)}
                    >
                      <i className="tim-icons icon-bag-16" />
                      Responsibility
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="tab-space"
                  activeTab={"link" + iconTabs1}
                >
                  <TabPane tabId="link1">
                    <span className="font-weight-bold">
                      Company Name&nbsp;&nbsp;:{" "}
                    </span>{" "}
                    <span>&nbsp;VIRTUOSOSYS</span>
                    <br />
                    <span className="font-weight-bold">
                      Designation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </span>{" "}
                    <span>&nbsp;Full Stack Developer</span>
                    <br />
                    <span className="font-weight-bold">
                      Experience&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                    </span>
                    <span>&nbsp;2020-2022</span>
                  </TabPane>
                  <TabPane tabId="link2">
                    <p>
                      <span className="font-weight-bold">Project Name:</span>{" "}
                      Torchlite, <br />
                      <span className="font-weight-bold">Role:</span>{" "}
                      <span className="pl-5 ml-3"> Team Member,</span> <br />
                      <span className="font-weight-bold">Environment:</span>
                      <span className="pl-2">
                        React, SASS, Node, Pgadmin,AWS-S3, Redux Torchlite is an
                        innovative partner platform built for B2B SaaS
                        companies. Drive revenue and be a part of shaping the
                        future of partner engagement.
                      </span>
                    </p>
                  </TabPane>
                  <TabPane tabId="link3">
                    <ul>
                      <li>Implementing a lot of Modules.</li>
                      <li>Fetching Salesforce data using webhook triggers.</li>
                      <li>
                        CRUD operation with sending mail using sendgrid API.
                      </li>
                      <li>Using AWS S3 for file purposes.</li>
                      <li>Salesforce-Webhook & Write Apex-class</li>
                    </ul>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          {/* <Col className="ml-auto" md="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 4,
                      })}
                      onClick={(e) => setTextTabs(4)}
                      
                    >
                      <i className="tim-icons icon-spaceship" />
                      Project
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 5,
                      })}
                      onClick={(e) => setTextTabs(5)}
                      
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                      Technology
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 6,
                      })}
                      onClick={(e) => setTextTabs(6)}
                      
                    >
                      <i className="tim-icons icon-bag-16" />
                      Responsibility
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + textTabs}>
                  <TabPane tabId="link4">
                    <li>Library Management,</li>
                    <li>Fog Fashion,</li>
                    <li>Moira Buildings,.etc</li>
                  </TabPane>
                  <TabPane tabId="link5">
                    <li>React & Node</li>
                    <li>HTML & CSS</li>
                    <li>Postgress</li>
                    <li>SASS</li>
                    <li>Bootstrap</li>
                    <li>Redux</li>
                  </TabPane>
                  <TabPane tabId="link6">
                    <ul>
                      <li>Build from the Scratch.</li>
                      <li>Using Component based File Structure</li>
                      <li>
                        CRUD operation with sending mail using sendgrid API.
                      </li>
                      <li>Using AWS S3 for file purposes.</li>
                      <li>Deployed Using Heroku</li>
                    </ul>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Experience;
