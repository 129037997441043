import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "reactstrap";
import img from "./study.png";
const Education = () => {
  const [miniModal, setMiniModal] = React.useState(false);
  const [year, setYear] = React.useState();
  const [percentage, setPercentage] = React.useState();

  const sslc = () => {
    setYear("2012-2013");
    setPercentage("87%");
    setMiniModal(!miniModal);
  };
  const hsc = () => {
    setYear("2014-2015");
    setPercentage("79%");
    setMiniModal(!miniModal);
  };
  const be = () => {
    setYear("2015-2019");
    setPercentage("70%");
    setMiniModal(!miniModal);
  };
  return (
    <div>
      {/* Start Mini Modal */}
      <Modal
        modalClassName="modal-mini modal-primary modal-mini"
        isOpen={miniModal}
        toggle={() => setMiniModal(false)}
      >
        <div className="modal-header justify-content-center">
          <button className="close" onClick={() => setMiniModal(false)}>
            <i className="tim-icons icon-simple-remove text-white" />
          </button>
          <div className="modal-pic">
            <img src={img} alt="" />
          </div>
        </div>
        <div className="modal-body">
          <Table className="tablesorter">
            <tbody>
              <tr>
                <td>Year:</td>
                <td>{year}</td>
              </tr>
              <tr>
                <td>Percentage:</td>
                <td>{percentage}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-primary"
            color="link"
            onClick={() => setMiniModal(false)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
      <Container>
        <Card className="card-plain">
          <CardHeader>
            <h1 className="profile-title text-left">Education</h1>
            <h5 className="text-on-back">03</h5>
          </CardHeader>
          <CardBody>
            <Row className="d-flex text-center">
              <Col sm={4} md={4}>
                <Button color="info" onClick={() => sslc(true)}>
                  SSLC
                </Button>
              </Col>
              <Col sm={4} md={4}>
                <Button color="info" onClick={() => hsc(true)}>
                  HSC
                </Button>
              </Col>
              <Col sm={4} md={4}>
                <Button color="success" onClick={() => be(true)}>
                  ENGINEERING
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Education;
