import classnames from "classnames";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import ContactUs from "./component/ContactUs";
import Education from "./component/Education";
import Experience from "./component/Experience";
import Project from "./component/Project";
import SocialIcon from "./component/SocialIcon";

let ps = null;

export default function ContactPage() {
  const [tabs, setTabs] = React.useState(1);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  }, []);

  let pageName = document.getElementById("pageName");
  pageName.innerHTML = "My Profile";
  return (
    <>
      <ExamplesNavbar />
      <div className="page-header">
        {/* <img
          alt="..."
          className="dots"
          src={require("assets/img/dots.png").default}
        />
        <img
          alt="..."
          className="path"
          src={require("assets/img/path4.png").default}
        /> */}
        <Container className="align-items-center">
          <Row>
            <Col lg="6" md="6">
              <h1 className="profile-title text-left">Krrish MK</h1>
              <h5 className="text-on-back">01</h5>
              <SocialIcon classname="hide-on-mobile" />
            </Col>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-coin card-plain">
                <CardHeader>
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    src={require("assets/img/MK.jpg").default}
                  />
                  <h3 className="title">Muthu Krishnan</h3>
                  <p className="text-center">4+ years Experience</p>
                  <h4 className="text-center font-weight-bold">
                    Technical Lead
                  </h4>
                </CardHeader>
                <CardBody>
                  <Nav className="nav-tabs-primary justify-content-center" tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: tabs === 1,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          setTabs(1);
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: tabs === 2,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          setTabs(2);
                        }}
                      >
                        Address
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    className="tab-subcategories"
                    activeTab={"tab" + tabs}
                  >
                    <TabPane tabId="tab1">
                      <Table className="tablesorter" responsive>
                        <tbody>
                          <tr>
                            <td>AGE</td>
                            <td>26</td>
                          </tr>
                          <tr>
                            <td>DOB</td>
                            <td>22-06-1998</td>
                          </tr>
                          <tr>
                            <td>LANGUAGE</td>
                            <td>English, Tamil, Kannada</td>
                          </tr>
                          <tr>
                            <td>GMAIL</td>
                            <td>muthukrrish333@gmail.com</td>
                          </tr>
                          <tr>
                            <td>MOBILE</td>
                            <td>9994171573</td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="tab2">
                      <Table className="tablesorter" responsive>
                        <h6>Permanent Address</h6>
                        <tbody>
                          <tr>
                            <td>
                              56/11 Big Thaikkal Street
                              <br />
                              Mayiladuthurai - 609001
                            </td>
                          </tr>
                        </tbody>
                        <h6>Current Address</h6>
                        <tbody>
                          <tr>
                            <td>
                              Bellandur
                              <br />
                              Bangalore - 560103
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <SocialIcon classname="show-on-mobile d-flex justify-content-center" />
            </Col>
          </Row>
        </Container>
      </div>
      <Experience />
      <Education />
      <Project />
      <ContactUs />
    </>
  );
}
