import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import whatsapp from "./whatsapp.png";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

import bigChartData from "variables/charts.js";
import Notifications from "views/IndexSections/Notifications";

export default function LandingPage() {
  const [miniModal, setMiniModal] = React.useState(false);
  let pageName = document.getElementById("pageName");
  pageName.innerHTML = "Future Innovation";
  return (
    <>
      {/* <ExamplesNavbar /> */}
      <div>
        {/* Start Mini Modal */}
        <Modal
          modalClassName="modal-mini modal-primary modal-mini"
          isOpen={miniModal}
          toggle={() => setMiniModal(false)}
        >
          <div className="modal-header justify-content-center">
            <button className="close" onClick={() => setMiniModal(false)}>
              <i className="tim-icons icon-simple-remove text-white" />
            </button>
            <div className="">
              {/* <FontAwesomeIcon icon="fa-brands fa-whatsapp" /> */}
              <img src={whatsapp} alt="" />
              {/* <i className="fa fa-whatsapp" style={{color:"green"}} /> */}
            </div>
          </div>
          <div className="modal-body">
            <p>Your want any Detail Just Contact me on....</p>
          </div>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-neutral"
              color="link"
              onClick={() => setMiniModal(false)}
              type="button"
            >
              Close
            </Button>
            <Button
              className="btn-neutral"
              color="link"
              onClick={() =>
                (window.location.href =
                  "https://api.whatsapp.com/send?phone=+919994171573&text=Hello%21%21%21%20%20%20Krrish%20MK.")
              }
              type="button"
            >
              Get Started
            </Button>
          </div>
        </Modal>
        <div className="page-header">
          {/* <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          /> */}
          <div className="content-center">
            {/* <Notifications /> */}
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="10" md="8">
                <h1 className="text-white pt-5">
                  <span className="text-danger">Who We</span> Are ?
                </h1>
                <h4 className="text-white mb-3">
                  We're ecstatic to unveil Namma Tech, your ultimate destination
                  for all things development. With a laser focus on responsive
                  web design and cutting-edge mobile app solutions, we craft
                  each project with bespoke features in mind.
                </h4>
                <h4 className="text-white">
                  Namma Tech emerged from the visionary collaboration of three
                  engineering maestros, fueled by a relentless passion for
                  innovation and excellence.
                </h4>
              </Col>
              {/* <Col lg="2" md="4">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/etherum.png").default}
                />
              </Col> */}
            </Row>
          </div>
        </div>
        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-trophy text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">3,237</CardTitle>
                                <p />
                                <p className="card-category">Awards</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats upper bg-default">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-coins text-white" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">3,653</CardTitle>
                                <p />
                                <p className="card-category">Commits</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-gift-2 text-info" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">593</CardTitle>
                                <p />
                                <p className="card-category">Presents</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-credit-card text-success" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">10,783</CardTitle>
                                <p />
                                <p className="card-category">Forks</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>
                      My <span className="text-danger">Story</span>
                    </h1>
                    <h4>
                      <span className="font-weight-bold">
                        <span className="text-danger"> நம்ம</span> TECH
                      </span>{" "}
                      is Renowned as a Freelancing Project Center, we're
                      celebrated for our tailor-made projects spanning diverse
                      domains. Elevating businesses' online presence, we deliver
                      captivating, responsive websites that magnetize customers.
                      Partner with us to amplify your digital footprint and
                      captivate your audience like never before!
                    </h4>
                    <br />
                    <h4>
                      We have worked on 50+ projects on web development and
                      mobile applications to our clients.
                    </h4>
                    <h4>
                      We're committed to delivering top-tier websites that drive
                      unparalleled growth for your business.
                    </h4>
                    <br />
                    {/* <a
                      className="font-weight-bold text-info mt-5"
                      onClick={(e) => e.preventDefault()}
                    >
                      Show all{" "}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="">
          <Container>
            {/* <Row className="justify-content-center">
              <Col lg="12"> */}
            <h1 className="text-center">Service</h1>
            <Row className="row-grid justify-content-center">
              <Col lg="4">
                <div className="info">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-money-coins" />
                  </div>
                  <h4 className="info-title">Technology We Use</h4>
                  <hr className="line-primary" />
                  <p>
                    We harness cutting-edge technologies such as React, Node.js,
                    Flutter, and React Native, seamlessly integrated with both
                    SQL and NoSQL databases.
                  </p>
                </div>
              </Col>
              <Col lg="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="tim-icons icon-chart-pie-36" />
                  </div>
                  <h4 className="info-title">Our Specializations</h4>
                  <hr className="line-warning" />
                  <p>
                    Our expertise lies in crafting responsive websites with
                    advanced SEO capabilities, as well as customizing datasets
                    for e-commerce platforms.
                  </p>
                </div>
              </Col>
              <Col lg="4">
                <div className="info">
                  <div className="icon icon-success">
                    <i className="tim-icons icon-single-02" />
                  </div>
                  <h4 className="info-title">Financial Cost</h4>
                  <hr className="line-success" />
                  <p>
                    Recognizing that cost is a major concern for technical
                    centers, we provide competitive rates that fit comfortably
                    within your budget.
                  </p>
                </div>
              </Col>
            </Row>
            {/* </Col>
            </Row> */}
          </Container>
        </section>
        <section className="section section-lg section-safe">
          <Container>
            <Row className="row-grid pb-5 justify-content-between">
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>
                    Web <span className="text-danger">Development</span>
                  </h3>
                  <p>
                    Our dedicated web development team collaborates closely with
                    you to bring your vision to life, whether it's a static or
                    dynamic website. Additionally, our specialized product team
                    is on hand to assist with brainstorming and implementing new
                    ideas.
                  </p>
                  <p>
                    We're crafting an e-commerce website tailored specifically
                    to your product business, complete with seamless payment
                    gateways. Additionally, we offer services to add products
                    and customize the application according to your needs.
                  </p>
                </div>
              </Col>
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("./component/assets/img/website.jpg").default}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">100%</CardTitle>
                        <p className="card-category text-white">Responsive</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">47</CardTitle>
                        <p className="card-category text-white">
                          Satisfied students
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">17</CardTitle>
                        <p className="card-category text-white">
                          Business Clients
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="row-grid justify-content-between">
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("./component/assets/img/mobile.jpg").default}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">100%</CardTitle>
                        <p className="card-category text-white">Safe</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">105 K</CardTitle>
                        <p className="card-category text-white">
                          Satisfied customers
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">105</CardTitle>
                        <p className="card-category text-white">Business</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>
                    Mobile <span className="text-danger">Application</span>
                  </h3>
                  <p>
                    We're also crafting iOS and Android applications using the
                    popular Flutter technology, ensuring a seamless
                    cross-platform experience for your users.
                  </p>
                  <p>
                    We're developing a mobile application tailored to your
                    product business needs, equipped with integrated payment
                    gateways for seamless transactions. Additionally, our
                    services extend to adding products and customizing the
                    application to suit your preferences.
                  </p>
                  <ul className="list-unstyled mb-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <div className="ml-3">
                          <h6>Amazing Output view</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>Super friendly support team</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="row-grid justify-content-between">
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>
                    E-commerce <span className="text-danger">Development</span>
                  </h3>
                  <p>
                    Crafting Your E-commerce Empire: Our team specializes in
                    building robust online platforms tailored to your product
                    business needs. From seamless payment gateways to
                    captivating product displays, we ensure a user-friendly
                    shopping experience that boosts your sales. Let us bring
                    your e-commerce vision to life with precision and flair.
                  </p>
                </div>
              </Col>
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("./component/assets/img/ecom.jpg").default}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">100%</CardTitle>
                        <p className="card-category text-white">Safe</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">573 K</CardTitle>
                        <p className="card-category text-white">
                          Satisfied customers
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">10 425</CardTitle>
                        <p className="card-category text-white">Business</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg">
          <Col md="12">
            <Card className="card-chart card-plain">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <hr className="line-info" />
                    <h5 className="card-category">Total Porjects</h5>
                    <CardTitle tag="h2">Performance</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={bigChartData.data}
                    options={bigChartData.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </section>
        {/* <section className="section section-lg section-coins">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path3.png").default}
          />
          <Container>
            <Row>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                  Choose the Domain{" "}
                  <span className="text-info">which you want</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/bitcoin.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">ECE</h4>
                        <span>Department</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>IoT-Internet of Things</ListGroupItem>
                        <ListGroupItem>Embedded-System</ListGroupItem>
                        <ListGroupItem>Raspberry Pi</ListGroupItem>
                        <ListGroupItem>GPS | GSM Projects</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-simple"
                      color="primary"
                      onClick={() => setMiniModal(true)}
                    >
                      Get Projects
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ripp.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">CSE</h4>
                        <span>Department</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Face Recognition</ListGroupItem>
                        <ListGroupItem>Web Page Creation</ListGroupItem>
                        <ListGroupItem>Image Processing</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-simple"
                      color="info"
                      onClick={() => setMiniModal(true)}
                    >
                      Get Project
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/etherum.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">EEE</h4>
                        <span>Department</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Embedded System</ListGroupItem>
                        <ListGroupItem>IoT</ListGroupItem>
                        <ListGroupItem>Power System</ListGroupItem>
                        <ListGroupItem>GPS | GSM Projects</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-simple"
                      color="success"
                      onClick={() => setMiniModal(true)}
                    >
                      Get Project
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </section> */}
        <Footer />
      </div>
    </>
  );
}
