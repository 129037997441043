import React from "react";

// reactstrap components
import { Container } from "reactstrap";

export default function PageHeader() {
  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand">
          <h3 className="h1-seo">Crafting Digital Brilliance</h3>
          <h3 className="text-danger">Your Vision, Our Expertise!</h3>
          <h4 className="d-none d-sm-block">
            Passionate web wizard crafting digital experiences with pixel
            perfection. Specializing in sleek designs, smooth user interfaces,
            and seamless functionality. Fluent in the languages of HTML, CSS,
            and JavaScript, with a knack for turning ideas into stunning
            realities. Let's collaborate to transform your vision into an online
            masterpiece!
          </h4>
        </div>
      </Container>
    </div>
  );
}
